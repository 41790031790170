// 单页栏目
import request from '@/util/request.js'

// 获取单页栏目详细信息
export function SingleInfo(id) {
	return request({
		method: 'get',
		url: `/api/singleInfo/${id}`
	})
}
