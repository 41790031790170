<template>
	<div class="siXunFaWu" id="siXunFaWu">
		<!-- 主体 -->
		<div class="page-content">
			<h-img class="bg" :src="$store.getters.getMenuItem('siXunFaWu').frontImg"></h-img>
			<div class="rich-pc" style="font-size: 16px;max-width: 1200px;margin: 0 auto;" v-html="singleInfo.content"></div>
			<div class="rich-mobile" style="font-size: 16px;max-width: 1200px;margin: 0 auto;padding: 0 12px;" v-html="singleInfo.content && formatRichText(singleInfo.content)"></div>
		</div>
	</div>
</template>

<script>
	import {SingleInfo} from '@/apis/danYeLanMu.js'
	export default {
		data() {
			return {
				singleInfo: {}
			}
		},
		created() {
			this.getSingleInfo()
		},
    mounted() {
      new WOW({
        boxClass: 'wow',
        animateClass: 'animated',
        offset: this.isMobile ? 0:150,
        mobile: true,
        live: false
      }).init();
    },
    methods: {
			getSingleInfo() {
				SingleInfo(1).then(res => {
					this.singleInfo = res.data
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import './siXunFaWu.scss';
	@import './siXunFaWuMedia.scss';
</style>
